import * as amplitude from '@amplitude/analytics-browser';
import { BrowserClient } from '@amplitude/analytics-types';
import { AuthenticatedUser, Tracker } from './types';

const DEV_ENVIRONMENTS = ['local', 'dev', 'qa'];

export default class AmplitudeTracker implements Tracker {
  prodInstance: BrowserClient;

  devInstance: BrowserClient;

  instance: BrowserClient;

  constructor(amplitudeTrackingIDs: { prod: string; dev: string }) {
    this.prodInstance = amplitude.createInstance();
    this.prodInstance.init(amplitudeTrackingIDs.prod, { defaultTracking: false });
    this.devInstance = amplitude.createInstance();
    this.devInstance.init(amplitudeTrackingIDs.dev, { defaultTracking: false });
    this.instance = this.prodInstance;
  }

  userLoggedIn = (user: AuthenticatedUser) => {
    if (DEV_ENVIRONMENTS.includes(user.envName) || user.email.includes('@cuttingroom.com')) {
      this.instance = this.devInstance;
    }
    const userId = `${user.name} @ ${user.tenant}.${user.envName}`;
    this.instance.setUserId(userId);
    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('name', user.name);
    identifyEvent.set('tenant', user.tenant);
    identifyEvent.set('environment', user.envName);
    identifyEvent.set('roles', user.roles);
    this.instance.identify(identifyEvent);

    this.instance.track('User logged in from Centralized authentication', {
      loginMethod: 'centralized-login',
      timestamp: new Date().toISOString(),
    });
  };

  captureExceptionWithExtras = (error: Error, info: unknown) => {
    this.instance.track('Exception caught', { error, info });
  };

  captureException = (error: Error) => {
    this.instance.track('Exception caught', { error });
  };
}

import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ActivateAccount from './components/ActivateAccount/ActivateAccount';
import Home from './components/Home/Home';
import LoginScreen from './components/LoginScreen/LoginScreen';
import RecoverPassword from './components/RecoverPassword/RecoverPassword';
import RecoverPasswordConfirmation from './components/RecoverPasswordConfirmation/RecoverPasswordConfirmation';

const { REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID = '' } = process.env;
const LDProvider = withLDProvider({
  clientSideID: REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
});

const App: React.FC = () => (
  <div className="App">
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/login/:tenant" element={<LoginScreen />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route
          path="/confirm-recover-password"
          element={<RecoverPasswordConfirmation />}
        />
        <Route path="/activate-account" element={<ActivateAccount />} />
      </Routes>
    </Router>

    <ToastContainer
      pauseOnHover={false}
      autoClose={2000}
      draggable={false}
      theme="dark"
    />
  </div>
);

export default LDProvider(App);

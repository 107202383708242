import AmplitudeTracker from './amplitude-tracker';
import SentryTracker from './sentry-tracker';
import IntercomTracker from './intercom-tracker';
import { Tracker, TrackersConfig, AuthenticatedUser, Extras } from './types';

const trackers: Tracker[] = [];

export function initMonitoring(config: TrackersConfig) {
  if (config.sentryDSN) {
    trackers.push(new SentryTracker(config.sentryDSN));
  }

  if (config.intercomAppId) {
    trackers.push(new IntercomTracker(config.intercomAppId));
  }

  if (config.amplitudeTrackingIDs) {
    trackers.push(new AmplitudeTracker(config.amplitudeTrackingIDs));
  }
}

export const userLoggedIn = (user: AuthenticatedUser) => {
  const theUser: AuthenticatedUser = {
    ...user,
    tenant: user.tenant || '',
    envName: user.envName || '',
  };

  trackers.forEach(async (tracker) => {
    if (tracker.userLoggedIn) {
      tracker.userLoggedIn(theUser);
    }
  });
};

export function captureException(error: Error, info?: Extras) {
  trackers.forEach(tracker => {
    if (info && tracker.captureExceptionWithExtras) {
      tracker.captureExceptionWithExtras(error, info);
    } else if (tracker.captureException) {
      tracker.captureException(error);
    }
  });
}

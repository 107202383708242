import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@cuttingroom/core-components';
import LoginForm from './LoginForm';
import { LoginContext } from './LoginProvider';
import SSOLogin from './SSOLogin';
import './style.scss';
import * as config from './utils/interface';

const style = {
  marginTop: '1rem',
  height: '1rem',
};

const LoginFormWrapper = () => {
  const { step, ssoConfig, setSsoConfig } = useContext(LoginContext);

  const navigate = useNavigate();

  const cleanupSsoDetails = () => {
    localStorage.removeItem(config.SSO_CONFIG);
    setSsoConfig(false);
    navigate('/');
  };

  return (
    <div className="login-wrapper">
      {(step !== config.PASSWORD_INPUT || !ssoConfig) && <LoginForm />}

      {step === config.PASSWORD_INPUT && ssoConfig && <SSOLogin /> }

      {step === config.PASSWORD_INPUT && ssoConfig && (
        <div className="secondary-login-form form-collapsed">
          <Button
            style={style}
            onClick={cleanupSsoDetails}
          >
            Switch CuttingRoom
          </Button>

          <div className="content"><LoginForm /></div>
        </div>
      )}
    </div>
  );
};

export default LoginFormWrapper;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initMonitoring } from './utils/tracking';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

initMonitoring({
  sentryDSN: 'https://d9ddacc704054a9abbe2427ac425d822@sentry.io/271571',
  intercomAppId: 'l2x3x54v',
  amplitudeTrackingIDs: {
    prod: 'e167933cd0e2cbcd316cc28e93ae7487',
    dev: '988db4991853ba6053a312912163cfbc',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as Sentry from '@sentry/react';
import { Extras, AuthenticatedUser } from './types';

export default class SentryTracker {
  private user?: string;

  constructor(sentryDSN: string) {
    this.user = undefined;

    Sentry.init({
      dsn: sentryDSN,
      normalizeDepth: 12,
      tracesSampleRate: 0.2,
    });
  }

  userLoggedIn = (user: AuthenticatedUser): void => {
    this.user = user.email;
    Sentry.setUser({ email: user.email });
  };

  captureException = (error: Error): void => {
    Sentry.captureException(error);
  };

  captureExceptionWithExtras = (error: Error, info: Extras): void => {
    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  };
}
